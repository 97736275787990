<template>
	<div class="main" v-loading="loading">
		<div class="block">
			<div class="date-picker margin-left">
				<el-date-picker value-format="yyyy-MM-dd" v-model="datePickerValue" @change="datePickerChange" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
			</div>
			<div class="search">
				 <el-button type="primary" @click="getStatistics">搜 索</el-button>
			</div>
		</div>
		<div class="line-chart" id="main"></div>
		<AdvertForm v-if="statisticsData" :statisticsData="statisticsData" style="margin-top: 40px;"></AdvertForm>
		<!-- <AdvertRecord style="margin-top: 40px;"></AdvertRecord> -->
	</div>
</template>

<script>
	import * as echarts from 'echarts';
	import AdvertForm from './AdvertForm.vue'
	import AdvertRecord from './AdvertRecord.vue'
	export default {
		components: {
			AdvertRecord,AdvertForm
		},
		data() {
			return {
				option: {
					darkMode: 'auto',
					title: {text: '平台统计'},
					tooltip: {trigger: 'axis'},
					legend: {data: ['Banner广告', '插屏广告', '激励视频广告','活跃用户']},
					grid: {left: '3%',right: '4%',bottom: '3%',containLabel: true},
					// toolbox: {feature: {saveAsImage: {}}},
					xAxis: {
						type: 'category',
						boundaryGap: false,
						data: ['0时', '1时', '2时', '3时', '4时', '5时', '6时', '7时', '8时', '9时', '10时', '11时', '12时', '13时',
							'14时', '15时', '16时', '17时', '18时', '19时', '20时', '21时', '22时', '23时'
						]
					},
					yAxis: {type: 'value'},
					series: [
						{
							name: 'Banner广告',
							type: 'line',
							stack: 'Total',
							data: []
						},
						{
							name: '插屏广告',
							type: 'line',
							stack: 'Total',
							data: []
						},
						{
							name: '激励视频广告',
							type: 'line',
							stack: 'Total',
							data: []
						},
						{
							name: '活跃用户',
							type: 'line',
							stack: 'Total',
							data: []
						}],
				},
				dateValue: '',
				loading: false,
				statisticsData:'',
				datePickerValue: '',
				search:{},
			}
		},
		mounted() {
			var date = new Date();
			var year = date.getFullYear();
			var month = date.getMonth() + 1;
			var day = date.getDate();
			this.dateValue = year + "-" + month + "-" + day;
			this.getStatistics();
			this.initChart();
		},
		methods: {
			datePickerChange(value){
				if(value){
					this.search.created_atstart = value[0] + ' 00:00:00';
					this.search.created_atend   = value[1] + ' 23:59:59';
				}else{
					this.search.created_atstart = '';
					this.search.created_atend   = '';
				}
			},
			getStatistics(){
				this.statisticsData = '';
				this.loading = true;
				this.$get('app-advert-statistics',this.search).then(res=>{
					let data = res.data;
					this.statisticsData = data;
					this.option.series[0].data = [];
					this.option.series[1].data = [];
					this.option.series[2].data = [];
					this.option.series[3].data = [];
					for (var hour = 0;hour <= 23;hour ++)
					{ 
					    this.option.series[0].data.push(data.banner.data[hour]);
						this.option.series[1].data.push(data.insert.data[hour]);
						this.option.series[2].data.push(data.incentive.data[hour]);
						this.option.series[3].data.push(data.user.data[hour]);
					}
					this.initChart();
					this.$forceUpdate();
					this.loading = false;
				}).catch(err=>{
					this.$notify.error({message: err || '网络请求错误'}),this.loading = false;
				})
			},
			initChart(){
				var chartDom = document.getElementById('main');
				var myChart = echarts.init(chartDom);
				
				this.option && myChart.setOption(this.option);
			}
		},
	}
</script>

<style>
	
	.main {
		width: 100%;
		height: 100%;
		background-color: #ffffff;
		padding: 20px;
	}

	.line-chart {
		margin-top: 30px;
		height: 400px;
		width: 95%;
	}
	
	.block {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	
	.search {
		margin-left: 20px;
	}
</style>